//settings
$fa-font-path: "../font";

// Colors //
$black: #121110;
$white: #fff;
$primary-color: #f00;
$gray: #BABABA;

//Fonts
$primary-font: 'Libre Baskerville', sans-serif;
