@import "variables";
// includes
@import "includes/helpers";
@import "includes/normalize";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
}

body {
    background-color: $black;
    color: $white;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
    background-image: url('../img/hero.png');
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat; 
    
    @media screen and (max-width: 600px){
        background-image: url('../img/hero-m.png');
        background-size: 100% auto;
        background-position: 50% rem(190);
    }
}

a{
    color: inherit;
    transition: .3s all;
    @include hover{
        color: $primary-color;
    }
}

#page-wrap{
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    flex-direction: column;
}

#header{
    padding: rem(30) 0;
    .container{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (max-width: 930px){
            flex-direction: column-reverse;
            align-items: center;
            justify-content:flex-start;
            .social{
                margin: rem(40) 0 rem(60);
            }
        }
    }
    h1{
        width: rem(290);
        margin: 0;
        
        @media screen and (max-width: 930px){
            width: rem(190);
        }
    }
    .social a{
        display: inline-block;
        @include hover{
            opacity: .6;
        }
        &+a{
            margin-left: rem(24);
        }
        @media screen and (max-width: 930px){
            width: rem(30);
            &+a{
                margin-lefT: rem(18);
            }
        }
    }

    
}

#footer{
    padding: rem(30) 0;
    
    @media screen and (max-width: 930px){
        padding-top: rem(100);
        background-image: linear-gradient(to top, $black, #0000);
        .copyright{
            text-align: center;
            font-size: rem(7);
        }
    }
    .container{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (max-width: 930px){
            flex-direction: column;
            align-items: center;
            justify-content:flex-end;
        }
    }

    .newsletter{
        .newsletter-thanks{
            margin: 0;
        }
        input{
            font-size: rem(14);
            padding: rem(7) 0;
            color: $white;
            border: none;
            background: none;
            border-bottom: 1px solid $white;

            &::placeholder{
                color: $white;
            }
        }

        button{
            font-size: rem(14);
            padding: rem(7) rem(12);
            color: $black;
            background-color: $white;
            border: none;
            @include hover{
                color: $white;
                background-color: $black;
            }
        }
    }
}

.container{
    // max-width: rem(1600);
}

.copyright{
    width: rem(600);
    max-width: 100%;
    font-size: rem(9);
    color: $gray;
    font-family: sans-serif;
    text-transform: uppercase;
    text-shadow: 0 0 2px #000;
    a{
        display: inline-block;
        text-decoration: none;
    }
}